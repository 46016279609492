// @ts-ignore
import { Typography } from '@experian-uk/corvetteuk-common-ui';
import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

import { Loading } from '@modules/core/loading';

import { useUserSession } from '@partnerships/common/modules/user-session';

import { TwoColumnContainer } from '@modules/core/two-column-container';
import { CppInformation } from '@modules/domain/cpp-information/cpp-information';

interface PageLayoutProps {
  title: string;
  metaTitle: string;
  metaDesc?: string;
  sidebar?: boolean;
}

export const PageLayout: FunctionComponent<PageLayoutProps> = ({ children, sidebar, title, metaTitle, metaDesc }): JSX.Element => {
  const [userSessionResult] = useUserSession();

  const main = (
    <main>
      <Typography.Headings.Primary>{title}</Typography.Headings.Primary>
      {children}
    </main>
  );

  const layoutDisplay = () => {
    if (sidebar) {
      return (
        <TwoColumnContainer sideBar={true}>
          {main}
          <CppInformation />
        </TwoColumnContainer>
      );
    }

    return main;
  };

  if (!userSessionResult) {
    return <Loading />;
  }

  return (
    <>
      <Helmet
        title={`Experian Identity Plus | ${title}`}
        meta={[
          {
            name: metaTitle,
            content: metaDesc,
          },
        ]}
      />
      {layoutDisplay()}
    </>
  );
};
